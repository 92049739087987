
/*1. Use a more-intuitive box-sizing model.*/
*, *::before, *::after {
	box-sizing: border-box;
}
/*2. Remove default margin*/
* {
	margin: 0;
}
/*3. Allow percentage-based heights in the application*/
html, body {
	height: 100%;
	-webkit-text-size-adjust:100%
}
/*Typographic tweaks!4. Add accessible line-height5. Improve text rendering*/
body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}
/*6. Improve media defaults*/
img, picture, video, canvas, svg {
	display: block;
	max-width: 100%;
}
/*7. Remove built-in form typography styles*/
input, button, textarea, select {
	font: inherit;
}
/*8. Avoid text overflows*/
p, h1, h2, h3, h4, h5, h6 {
	overflow-wrap: break-word;
}
/*9. Create a root stacking context*/
#root, #__next {
	isolation: isolate;
}
:root {
	--neon-pink-text:
    0 0 7px #fff,
    0 0 42px #bc13fe,
    0 0 92px #bc13fe,
    0 0 151px #bc13fe;
	--neon-pink-border:
    0 0 .2rem #fff,
    0 0 2rem #bc13fe,
    inset 0 0 1.3rem #bc13fe;

	--neon-button-border:
    0 0 .2rem #fff,
    0 0 2rem #70d1fd,
    inset 0 0 1.3rem #70d1fd;

	--fs-big: clamp(28px, 8vw, 50px);
	--fs-regular-p: clamp(14px, 2vw, 22px);
	--fs-button: clamp(12px, 2vw, 22px);
}
body {
	background-color: #000000;
	background-image: url("../assets/img/bg.jpg");
	background-size: cover;
    background-attachment: fixed;
	background-repeat: no-repeat;
	background-position: center center;
    font-family: Inter, sans-serif;
}
.container {
	text-align: center;
	border-radius: 1em;
	padding: 2em;
    height: inherit;
    min-height: max-content;
	min-width: 320px;
	max-width: 800px;
    margin: auto;

}
.container-child{   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    height: fit-content;
}
img.title-img {
	max-width: 350px;
    width: 70%;
}
img.subject-img {
	width: 650px;
    max-width: 80%;
}
h1 {
	color: #fff;
	text-shadow: var(--neon-pink-text);
	font-size: var(--fs-big);
	text-transform: uppercase;
    animation: pulsate 1.5s infinite alternate;
}
h2.question {
	color: #fff;
    font-size: clamp(18px, 4vw, 32px);
    font-weight: 100;
    text-transform: uppercase;
	margin-bottom: 10%;
}

.product-content h3{
    color: #fff;
}

p {
	font-size: var(--fs-regular-p);
	color: #fff;
}
.container p {  
    margin-bottom: calc(2em + 1vh);
    font-weight: 100;
}

.container .sub-p-2 {
    margin-bottom: 3vh;
	font-weight: 400;
    font-size: clamp(14px, 1.4vw, 18px);
    font-style: italic;
}
.info {
	font-size: 1.5em;
}

.answers{
    margin-bottom: 5vh;
}


.answers.two-columns {
	display: grid;
    grid-template-columns: repeat(2, 50%);
    gap: 0;
	margin-bottom: 3vh;
}

.answers.two-columns .answer{
	width: 90%;
	display: flex;
    align-items: center;
    justify-content: center;
	min-height: 70px;
}

.answer {
	color: #fff;
	border-radius: 10px;
	padding: 1em;
	text-align: center;
	cursor: pointer;
	width: 80%;
	margin: 10px auto 10px;
    border: 2 solid #fff;
	box-shadow: var(--neon-pink-border);
    font-size: var(--fs-regular-p);
    background-color: rgb(255 255 255 / 7%);
	transition: all 0.5s ease;

}
.answer:hover {
	background-color: rgba(255, 255, 255, 0.2);
}
.answer.selected {
	background-color: rgb(186 93 255);
}
.button {
	background-color: transparent;
	cursor: pointer;
	padding: 1em 3em;
	color: #fff;
	text-transform: uppercase;
	border: 2 solid #fff;
	border-radius: 100px;
	box-shadow: var(--neon-button-border);
    margin-bottom: 30px;
	font-size: var(--fs-button);
	transition: all 0.5s ease;

}

.next {
	width: 80%;
    display: block;
    margin: 0 auto 40px;
	
}
.button:hover,
.product-button:hover {
	background-color: #70d0fd3b;
}

.results-button{
    position: inherit;
	font-size: var(--fs-button);

}

.results-button .button {
    margin: 0 auto 1em;
    text-align: center;
	width: 100%;
}

.button-holder,
.results-button{
	padding: 0 20px 30px;
	margin: .5em auto;

}
.clearfix:before,.clearfix:after {
	content: " ";
	display: table;
}
.clearfix:after {
	clear: both;
}
.results-button,
.result-area-message {
	display: none;
}
#quiz-area {
	display: none;
}
.result-area{
	height: fit-content;
    margin-bottom: 30px;
    max-height: clamp(20vh, 70vh - 50px, 100vh);
    padding: 1em;
    display: none;
    overflow-y: scroll;
}
*::-webkit-scrollbar {
	width: 5px;
}
*::-webkit-scrollbar-track {
	background: #ffb2f4;
	border-radius: 20px;
}
*::-webkit-scrollbar-thumb {
	background-color: #fb00ff;
	border-radius: 20px;
}

.result-area-ul p {
    margin: auto;
}

ul.result-area-ul {
	list-style-type: none;
	padding: 10px;
	margin: 0;
}


.result-area-li {
    margin-bottom: 1em;
    padding: 20px;
    display: flex;
	background-color: rgb(255 255 255 / 10%);
    border-radius: 10px;
	align-items: center;
}

.result-area-li:hover {
    background-color: rgb(0 0 0 / 50%);
}
.product-image{
    width: 35%;
    padding: 10px;
}
.product-image img {
    border-radius: 10px;
	width: 100%;
    margin: auto;
}


.product-content {
	width: 65%;
    padding: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    gap: 1em;
}
.product-content a {
    width: fit-content;
}

.product-button{
	background-color: transparent;
	cursor: pointer;
	padding: .5em 2em;
	color: #fff;
	text-transform: uppercase;
	border: 2 solid #fff;
	border-radius: 100px;
	box-shadow: var(--neon-button-border);

}

.product-content .product-button,
.product-content h3 {
	font-size: clamp(12px, 2vw, 24px);
}

.progress-bar {
    width: 80%;
    background-color: #fff;
    border: 1px solid #fff;
	margin: 2vh auto 5vh;
}
.progress {
	height: 9px;
	box-shadow: var(--neon-pink-border);
}

.result-area-message {
    margin-bottom: 1em;
}
.result-area-message p {
    margin: auto;
}

.result-area-message .main-p {
    text-transform: uppercase;
	font-size: clamp(18px, 2vw, 32px);
	font-weight: 600;
}

.result-area-message .sub-p {
    font-size: clamp(14px, 1.6vw, 20px);
}

.result-area-message  .sub-p-2 {
    font-weight: 400;
    font-size: clamp(14px, 1.4vw, 18px);
    font-style: italic;
}

.result-area-message p span {
    color: rgb(255 255 143);
	font-weight: bold;
    font-style: normal;
}


@keyframes pulsate {
	100% {
		text-shadow:
        0 0 4px #fff,
        0 0 19px #fff,
        0 0 40px #bc13fe,
        0 0 90px #bc13fe,
        0 0 150px #bc13fe;
	}
	0% {
		text-shadow:
        0 0 2px #fff,
        0 0 6px #fff,
        0 0 10px #bc13fe,
        0 0 55px #bc13fe,
        0 0 80px #bc13fe;
	}
}


@media only screen and (max-width: 450px) {
	.result-area-li{
		display: flex;
		flex-direction: column;
	}
	
	.product-image {
		width:100%;
	}
	
	.product-content {
		width: 100%;
		text-align: center;
	}

	.product-content a {
		margin: auto;
	}
}
	